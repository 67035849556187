import Api from '@/utils/axios/Api';
const Http = Api.getInstance();





export const DeleteBrand = (id:number) => {
    return new Promise((resolve, reject) => {
        Http.delete(`brands/${id}`)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}
export const CreateBrand = (payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.post(`brands` , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}
export const UpdateBrand = (id:number , payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.put(`brands/${id}` , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}
export const FindBrand = (id:number) => {
    return new Promise((resolve, reject) => {
        Http.get(`brands/${id}`)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}

